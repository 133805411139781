<template>
  <div>
    <el-switch v-model="$store.state.standing" active-color="rgba(16, 140, 221, 1)"
               inactive-color="rgba(16, 140, 221, 1)" :active-value="2" :inactive-value="1" active-text="广告"
               inactive-text="媒介" @change="change">
    </el-switch>
  </div>
</template>

<script>
import {mapState, mapMutations} from 'vuex'

export default {
  computed: {
    ...mapState(['menu_active']),
  },
  data() {
    return {}
  },
  methods: {},
}
</script>

<style lang=scss scoped>
::v-deep .el-switch {
  margin: 20px 0;
}
</style>