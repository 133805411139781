<template>
  <div v-loading.fullscreen.lock="fullscreenLoading" style="padding-bottom: 5rem;
  box-sizing: border-box;">
    <!-- 个人中心 -->
    <div class="user_info">
      <notic :ScrollObj="ScrollObj"/>
      <!-- <standing_switch /> -->
      <Title title="个人信息"/>
      <div class="user flex">
        <div class="user_user_box flex">
          <div>
            <p class="name">用户名</p>
            <p class="info_0">{{ user_info.username }}</p>
          </div>
          <div>
            <p class="name">手机号</p>
            <p class="info_0">{{ user_info.mobile }}</p>
          </div>
          <div>
            <p class="name">会员等级</p>
            <p class="info_0">{{ user_info.group_name }}</p>
          </div>
          <div>
            <p class="name">账户余额</p>
            <p class="info_0">￥{{ user_info.guanggao_money }}</p>
          </div>
          <div>
            <p class="name">钱包</p>
            <p class="info_0">￥{{ user_info.guanggao_wallet }}</p>
          </div>
          <button class="topUp" @click="childTab({
    path: '/placard_user/placard_refill'
  })">立即充值
          </button>
        </div>
        <div class="flex" v-if="user_info.custom_info">
          <img :src="user_info.custom_info.avatar" alt="" style="width: 10rem;">
          <div class="user_name_info flex" v-if="user_info.custom_info">
            <p>专属客户经理</p>
            <p>姓名: {{ user_info.custom_info.nickname }}</p>
            <p>QQ: {{ user_info.custom_info.qq }}</p>
            <p>手机号: {{ user_info.custom_info.mobile }}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="user_info2">
      <Title title="常用功能"/>
      <div class="user2 flex">
        <div @click="childTab(item)" v-for="(item, index) in FunList" :key="index">
          <img :src="item.imgUrl" alt="">
          <p>{{ item.title }}</p>
        </div>
      </div>
    </div>
    <div class="user_info3">
      <div class="user_status flex">
        <Title title="近3天订单状态"/>
        <ul class="status_box flex">
          <li @click="tab_switch(item)" :class="order_status === item.id ? 'active' : ''"
              v-for="(item, index) in status_list" :key="index">{{ item.title }}
          </li>
        </ul>
      </div>
      <el-table :fit="true" :data="tableData" style="width: 100%;margin-bottom: 20px;" height="230">
        <el-table-column fixed prop="sn" label="订单号" width="150">
        </el-table-column>
        <el-table-column fixed prop="title" label="标题" width="300">
        </el-table-column>
        <el-table-column fixed prop="date" label="广告类别" width="150">
          <template slot-scope="scope">
            <p class="media_num">{{ scope.row.category_title }}</p>
          </template>
        </el-table-column>
        <el-table-column fixed prop="date" label="是否设计" width="150">
          <template slot-scope="scope">
            <p :style="{ color: scope.row.design_price !== '0.00' ? 'rgba(57, 204, 12, 1)' : 'rgba(209, 17, 17, 1)' }">
              {{ scope.row.design_price != '0.00' ? '是' : '否' }}</p>
          </template>
        </el-table-column>
        <el-table-column fixed prop="order_price" label="金额">
        </el-table-column>
        <el-table-column fixed prop="date" label="状态">
          <template slot-scope="scope">
            <p :style="{ color: status_list[scope.row.order_status + 1].color }">
              {{ status_list[scope.row.order_status + 1].title }}</p>
          </template>
        </el-table-column>
        <el-table-column fixed prop="order_time" label="下单时间">
        </el-table-column>
      </el-table>
    </div>
    <pagination :page="page" @pageChange="pageChange" :count="count" :total_page="total_page"/>

  </div>
</template>
<script>
import Title from '@/components/UserInfoTitle.vue'
import standing_switch from '@/components/standing_switch.vue'
import notic from '@/components/notic.vue'
import {mapState, mapMutations} from 'vuex'
import {timeCut} from '@/util/timeCut'

export default {
  name: '',
  components: {
    Title,
    notic,
    standing_switch
  },
  mounted() {
    this.getUserInfo()
    this.get_list()
    this.getNotic()

  },
  data() {
    return {
      ScrollObj: [],
      FunList: [
        {
          imgUrl: require('@/assets/user/index/zaixianchongzhi.png'),
          title: '在线充值',
          path: '/placard_user/placard_refill',
        },
        {
          imgUrl: require('@/assets/user/index/fapiaoshenqing.png'),
          title: '发票申请',
          path: '/user/placard_apply_for_invoice/placard_apply_for_invoice_data'
        },
        {
          imgUrl: require('@/assets/user/index/meitiziyuan.png'),
          title: '广告资源',
          path: '/placard_user/user_outdoors_placard_media',
        },
        // {
        //   imgUrl: require('@/assets/user/index/Chat.png'),
        //   title: '一言一文',
        //   activeTitle: '一言一文',
        // },
        {
          imgUrl: require('@/assets/user/index/changyonggongneng.png'),
          title: '工单管理',
          path: '/placard_user/my_place_order',
        },
        {
          imgUrl: require('@/assets/user/index/lishifabu.png'),
          title: '历史发布',
          path: '/placard_user/place_order'
        },
      ],
      status_list: [
        {
          id: '',
          title: '全部',
        },
        {
          id: 0,
          title: '未处理',
          color: 'rgba(51, 51, 51, 1)'
        },
        {
          id: 1,
          title: '制作中',
          color: 'rgba(235, 173, 59, 1)',
        },
        {
          id: 2,
          title: '已刊登',
          color: 'rgba(57, 204, 12, 1)',
        },
        {
          id: 3,
          title: '申请退款',
          color: 'rgba(235, 173, 59, 1)',
        },
        {
          id: 4,
          title: '拒绝退款',
          color: 'rgba(224, 25, 25, 1)',
        },
        {
          id: 5,
          title: '退单',
          color: 'rgba(224, 25, 25, 1)',
        },
        {
          id: 6,
          title: '拒单',
          color: 'rgba(224, 25, 25, 1)',
        },

      ],
      tableData: [],
      fullscreenLoading: false,
      page: 1,
      total_page: 0, //分页
      count: 0, //总条数
      order_status: '',
      user_info: {},
    }
  },
  methods: {
    getNotic() {
      this.curlGet('/api/gonggao').then(res => {
        if (res.data.code) {
          this.ScrollObj = res.data.data
        }
      })
    },

    pageChange(pageVal) {
      console.log(pageVal);
      this.page = pageVal
      this.get_list()
    },
    // 获取用户信息
    getUserInfo() {
      this.curlGet('/api/user/info', {}).then(res => {
        console.log(res.data)
        if (res.data.code) {
          this.user_info = res.data.data
          this.$user_info = res.data.data
          this.$util.setLocalStorage('user_info', res.data.data) // 写入local storage
        }
      })
    },
    tab_switch(item) {
      this.order_status = item.id
      this.pageChange(1)
    },
    childTab(item) {
      this.$router.push(item.path)
      this.$store.state.menu_active = item.path

    },
    get_list() {
      let data = {}
      data.page = this.page
      data.limit = 5
      if (this.order_status !== '') {
        data.order_status = this.order_status
      }
      this.curlGet('/api/users/guanggao_order/list', data).then(res => {
        if (res.data.code) {
          this.total_page = res.data.data.total_page
          this.count = res.data.data.count
          this.tableData = res.data.data.list
          this.tableData.forEach((item, index) => {
            this.tableData[index].order_time = timeCut(this.tableData[index].order_time * 1000)

          })
        }
      })
    }
  },
}
</script>

<style lang=scss scoped>
@import '@/scss/UserInfo'
</style>